import { gql } from '@apollo/client'

import { userFragments } from './User.gql'
import { errorMessageFragment } from './ErrorMessage.gql'
import { documentFragment } from './Document.gql'
import { photoFragment } from './Photo.gql'

export const commentFragments = {
  comment: gql`
    fragment commentFragment on Comment {
      id
      comment
      type
      phase
      pin
      isCocUpdate
      isFunFact
      files {
        ... on File {
          id
          name
          region
          key
          bucket
          created
          updated
          createdBy {
            ...compactUserFragment
          }
        }
        ...documentFragment
        ...photoFragment
      }
      createdBy {
        ...compactUserFragment
      }
      created
      updated
      deleted
    }
    ${userFragments.compactUser}
    ${documentFragment}
    ${photoFragment}
  `,
}

export const LIST_COMMENTS = gql`
  query ListComments($projectId: ID!, $type: String!, $phase: String) {
    listComments(projectId: $projectId, type: $type, phase: $phase) {
      ...commentFragment
    }
  }
  ${commentFragments.comment}
`

export const LIST_CONSTRUCTION_COMMENTS = gql`
  query Comments($projectId: ID!) {
    listComments(projectId: $projectId, type: "construction") {
      ...commentFragment
    }
  }
  ${commentFragments.comment}
`

export const ADD_COMMENT = gql`
  mutation AddComment($parentId: ID!, $input: CreateCommentInput!) {
    addComment(parentId: $parentId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...commentFragment
      }
    }
  }
  ${commentFragments.comment}
  ${errorMessageFragment}
`
// TODO: Rename from `addChecklistItemComment` to `AddChecklistItemComment`
export const ADD_CHECKLIST_ITEM_COMMENT = gql`
  mutation addChecklistItemComment(
    $checklistItemId: ID!
    $input: CreateCommentInput!
  ) {
    addChecklistItemComment(checklistItemId: $checklistItemId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...commentFragment
      }
    }
  }
  ${commentFragments.comment}
  ${errorMessageFragment}
`

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($id: ID!, $type: String, $input: UpdateCommentInput!) {
    updateComment(id: $id, type: $type, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...commentFragment
      }
    }
  }
  ${commentFragments.comment}
  ${errorMessageFragment}
`

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!, $type: String) {
    deleteComment(id: $id, type: $type) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...commentFragment
      }
    }
  }
  ${commentFragments.comment}
  ${errorMessageFragment}
`

import { gql } from '@apollo/client'

export const contactFragments = {
  contact: gql`
    fragment contactFragment on Contact {
      id
      name
      title
      email
      phone
    }
  `,
}

export const CREATE_CONTACT = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      item {
        ...contactFragment
      }
      errors {
        path
        message
      }
    }
  }
  ${contactFragments.contact}
`

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($id: ID!, $input: UpdateContactInput!) {
    updateContact(id: $id, input: $input) {
      ...contactFragment
    }
  }
  ${contactFragments.contact}
`

export const DELETE_CONTACT = gql`
  mutation DeleteContact($id: ID!) {
    deleteContact(id: $id) {
      ...contactFragment
    }
  }
  ${contactFragments.contact}
`

export const LIST_CONTACTS = gql`
  query ListContacts($filter: ContactFilter) {
    listContacts(filter: $filter) {
      ...contactFragment
    }
  }
  ${contactFragments.contact}
`

import { gql } from '@apollo/client'

import { userFragments } from './User.gql'

export const costSavingFragment = gql`
  fragment costSavingFragment on CostSaving {
    id
    phase
    implemented
    evaluated
    created
    createdBy {
      ...compactUserFragment
    }
  }
  ${userFragments.compactUser}
`

export const ADD_COSTSAVING = gql`
  mutation AddCostSaving($input: CreateCostSavingInput!) {
    addCostSaving(input: $input) {
      ...costSavingFragment
    }
  }
  ${costSavingFragment}
`

export const UPDATE_COSTSAVING = gql`
  mutation UpdateCostSaving(
    $id: ID!
    $projectId: ID!
    $input: UpdateCostSavingInput!
  ) {
    updateCostSaving(id: $id, projectId: $projectId, input: $input) {
      ...costSavingFragment
    }
  }
  ${costSavingFragment}
`

export const DELETE_COSTSAVING = gql`
  mutation DeleteCostSaving($id: ID!) {
    deleteCostSaving(id: $id) {
      ...costSavingFragment
    }
  }
  ${costSavingFragment}
`

import { gql } from '@apollo/client'

import { userFragments } from './User.gql'
import { AttachmentFragment } from './Attachment.gql'

export const ObservationFragment = gql`
  fragment ObservationFragment on Observation {
    id
    # inspection {
    #   ...InspectionFragment
    # }
    safe
    status
    actionRequired
    actionTaken
    severity
    comments
    recommendations
    imageID
    image {
      ...AttachmentFragment
    }
    showOpenItemsOnly
    due
    updated
    inspector {
      ...userFragment
    }
  }
  ${userFragments.user}
  ${AttachmentFragment}
`

export const ObservationCategoryFragment = gql`
  fragment ObservationCategoryFragment on ObservationCategory {
    id
    name
    count
    observations {
      ...ObservationFragment
    }
  }
  ${ObservationFragment}
`

import { gql } from '@apollo/client'

import { vistaCostFragment } from './VistaCost.gql'
import { vistaProjectInfoFragment } from './VistaProjectInfo.gql'
import { teamMemberFragments } from './TeamMemberInterface.gql'
import { linkFragments } from './Link.gql'
import { errorMessageFragment } from './ErrorMessage.gql'
import { InspectionFragment } from './Inspection.gql'

export const constructionFragment = gql`
  fragment constructionFragment on Construction {
    id
    active
    created
    updated
    feeRisk
    cashFlowRisk
    gcgrRisk
    billingRisk
    cost {
      ...vistaCostFragment
    }
    projectInfo {
      ...vistaProjectInfoFragment
    }
    teamMembers {
      ...teamMemberFragment
    }
    droneDeployLinks {
      ...linkFragment
    }
    safetyInspections {
      ...InspectionFragment
    }
    qualityInspections {
      ...InspectionFragment
    }
  }
  ${vistaCostFragment}
  ${vistaProjectInfoFragment}
  ${teamMemberFragments.teamMember}
  ${linkFragments.link}
  ${InspectionFragment}
`

export const constructionResultFragment = gql`
  fragment constructionResultFragment on ConstructionResult {
    errors {
      ...errorMessageFragment
    }
    item {
      ...constructionFragment
    }
  }
  ${constructionFragment}
  ${errorMessageFragment}
`

export const ADD_CONSTRUCTION = gql`
  mutation AddConstruction($projectId: ID!, $input: CreateConstructionInput!) {
    addConstruction(projectId: $projectId, input: $input) {
      ...constructionResultFragment
    }
  }
  ${constructionResultFragment}
`

export const UPDATE_CONSTRUCTION = gql`
  mutation UpdateConstruction(
    $projectId: ID!
    $input: UpdateConstructionInput!
  ) {
    updateConstruction(projectId: $projectId, input: $input) {
      ...constructionResultFragment
    }
  }
  ${constructionResultFragment}
`

import { gql } from '@apollo/client'

import { userFragments } from './User.gql'
import { errorMessageFragment } from './ErrorMessage.gql'

export const partnerFragments = {
  partner: gql`
    fragment partnerFragment on Partner {
      id
      name
      trade
      comment
      createdBy {
        ...userFragment
      }
      created
      updated
    }
    ${userFragments.user}
  `,
}

const partnerResultFragment = gql`
  fragment partnerResultFragment on PartnerResult {
    errors {
      ...errorMessageFragment
    }
    item {
      ...partnerFragment
    }
  }
  ${partnerFragments.partner}
  ${errorMessageFragment}
`

export const ADD_PARTNER = gql`
  mutation AddPartner($projectId: ID!, $input: CreatePartnerInput!) {
    addPartner(projectId: $projectId, input: $input) {
      ...partnerResultFragment
    }
  }
  ${partnerResultFragment}
`

export const DELETE_PARTNER = gql`
  mutation DeletePartner($id: ID!) {
    deletePartner(id: $id) {
      ...partnerResultFragment
    }
  }
  ${partnerResultFragment}
`

export const UPDATE_PARTNER = gql`
  mutation UpdatePartner($id: ID!, $input: UpdatePartnerInput!) {
    updatePartner(id: $id, input: $input) {
      ...partnerResultFragment
    }
  }
  ${partnerResultFragment}
`

import { gql } from '@apollo/client'

import { userFragments } from './User.gql'
import { errorMessageFragment } from './ErrorMessage.gql'

export const linkFragments = {
  link: gql`
    fragment linkFragment on Link {
      id
      name
      url
      type
      createdBy {
        ...userFragment
      }
      created
      updated
      deleted
    }
    ${userFragments.user}
  `,
}

export const ADD_LINK = gql`
  mutation AddLink($projectId: ID!, $input: CreateLinkInput!) {
    addLink(projectId: $projectId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...linkFragment
      }
    }
  }
  ${linkFragments.link}
  ${errorMessageFragment}
`

export const DELETE_LINK = gql`
  mutation DeleteLink($id: ID!) {
    deleteLink(id: $id) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...linkFragment
      }
    }
  }
  ${linkFragments.link}
  ${errorMessageFragment}
`

export const UPDATE_LINK = gql`
  mutation UpdateLink($id: ID!, $projectId: ID!, $input: UpdateLinkInput!) {
    updateLink(id: $id, projectId: $projectId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...linkFragment
      }
    }
  }
  ${linkFragments.link}
  ${errorMessageFragment}
`

export const LIST_LINKS = gql`
  query ListLinks($projectId: ID!, $type: String!) {
    listLinks(projectId: $projectId, type: $type) {
      ...linkFragment
    }
  }
  ${linkFragments.link}
`

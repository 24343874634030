import { gql } from '@apollo/client'

export const LIST_PROCORE_PHOTOS = gql`
  query ProcorePhotos(
    $projectId: ID!
    $filters: ProcorePhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    procorePhotos(
      procoreProjectId: $projectId
      filters: $filters
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        thumbnailUrl
        filename
        description
        starred
        categoryId
        categoryName
        taken
        location
      }
      nextToken
    }
  }
`

export default { LIST_PROCORE_PHOTOS }

import { gql } from '@apollo/client'

import { userFragments } from './User.gql'
import { errorMessageFragment } from './ErrorMessage.gql'

export const uniqueFeatureFragments = {
  uniqueFeature: gql`
    fragment uniqueFeatureFragment on UniqueFeature {
      id
      description
      plannedResolution
      implementedResolution
      createdBy {
        ...userFragment
      }
      created
      updated
      deleted
    }
    ${userFragments.user}
  `,
}

const uniqueFeatureResultFragment = gql`
  fragment uniqueFeatureResultFragment on UniqueFeatureResult {
    item {
      ...uniqueFeatureFragment
    }
    errors {
      ...errorMessageFragment
    }
  }
  ${uniqueFeatureFragments.uniqueFeature}
  ${errorMessageFragment}
`

export const ADD_UNIQUE_FEATURE = gql`
  mutation AddUniqueFeature(
    $projectId: ID!
    $input: CreateUniqueFeatureInput!
  ) {
    addUniqueFeature(projectId: $projectId, input: $input) {
      ...uniqueFeatureResultFragment
    }
  }
  ${uniqueFeatureResultFragment}
`

export const UPDATE_UNIQUE_FEATURE = gql`
  mutation UpdateUniqueFeature(
    $id: ID!
    $projectId: ID!
    $input: UpdateUniqueFeatureInput!
  ) {
    updateUniqueFeature(id: $id, projectId: $projectId, input: $input) {
      ...uniqueFeatureResultFragment
    }
  }
  ${uniqueFeatureResultFragment}
`

export const DELETE_UNIQUE_FEATURE = gql`
  mutation DeleteUniqueFeature($id: ID!, $projectId: ID!) {
    deleteUniqueFeature(id: $id, projectId: $projectId) {
      ...uniqueFeatureResultFragment
    }
  }
  ${uniqueFeatureResultFragment}
`

import { gql } from '@apollo/client'

import { milestoneFragments } from './Milestone.gql'
import { checklistFragments } from './Checklist.gql'
import { userFragments } from './User'

export const companyFragment = gql`
  fragment companyFragment on Company {
    id
    domain
    enablePM
    enableGC
    # active
    regions {
      id
      vistaCompanies
      name
      # company
      # programs
      # projects
      # active
      # createdBy
      # created
      # updated
      # deleted
    }
    programs {
      id
      name
      active
    }
    # projects
    # permissionTemplates
    projectPhases {
      order
      name
    }
    projectTypes {
      order
      name
    }
    preconPhases {
      order
      name
    }
    preconMilestones {
      ...milestoneFragment
    }
    preconDeliveryTypes {
      id
      name
      created
    }
    preconConstructionTypes {
      id
      name
      created
    }
    preconChecklists {
      ...checklistFragment
    }
    preconManagers {
      ...userFragment
    }
    clientManagers {
      ...userFragment
    }
  }
  ${milestoneFragments.milestone}
  ${checklistFragments.checklist}
  ${userFragments.user}
`

export const GET_COMPANY = gql`
  query GetCompany($id: ID!) {
    getCompanyById(id: $id) {
      ...companyFragment
    }
  }
  ${companyFragment}
`

export const GET_COMPANY_FOR_PROCEDEO = gql`
  query GetCompanyForProcedeo($id: ID!) {
    getCompanyById(id: $id) {
      ...companyFragment
      procedeoBilling {
        projectCount
        sumProgramValue
        sumProcedeoValue
        sumPaidToDate
        sumRemainingToBePaid
      }
    }
  }
  ${companyFragment}
`

export const LIST_COMPANIES = gql`
  query Companies {
    companies {
      id
      domain
    }
  }
`

import { gql } from '@apollo/client'

export const photoFragment = gql`
  fragment photoFragment on Photo {
    id
    name
    bucket
    region
    key
    phase
    created
    updated
    type
    createdBy {
      name
      email
    }
  }
`

export const addPhoto = gql`
  mutation AddPhoto($input: CreatePhotoInput!) {
    addPhoto(input: $input) {
      ...photoFragment
    }
  }
  ${photoFragment}
`

export const updatePhoto = gql`
  mutation UpdatePhoto($id: ID!, $projectId: ID!, $input: UpdatePhotoInput!) {
    updatePhoto(id: $id, projectId: $projectId, input: $input) {
      ...photoFragment
    }
  }
  ${photoFragment}
`

export const deletePhoto = gql`
  mutation DeletePhoto($id: ID!, $projectId: ID!) {
    deletePhoto(id: $id, projectId: $projectId) {
      ...photoFragment
    }
  }
  ${photoFragment}
`

import { gql } from '@apollo/client'

export const clientFragment = gql`
  fragment clientFragment on Client {
    id
    name
    vistaCompanyNumber
    businessUnits
    regions
  }
`

const ConstructionDashboardClientFragment = gql`
  fragment ConstructionDashboardClientFragment on Client {
    id
    name
  }
`

export const LIST_CLIENTS = gql`
  query ListClients($filter: ClientFilter) {
    listClients(filter: $filter) {
      ...clientFragment
    }
  }
  ${clientFragment}
`

export const GET_CLIENT = gql`
  query GetClient($id: ID!) {
    getClient(id: $id) {
      ...clientFragment
    }
  }
  ${clientFragment}
`

export const LIST_CLIENTS_BY_COMPANY = gql`
  query ListClientsByCompany($company: ID!) {
    listClientsByCompany(company: $company) {
      ...ConstructionDashboardClientFragment
    }
  }
  ${ConstructionDashboardClientFragment}
`

export default {
  LIST_CLIENTS,
  GET_CLIENT,
  LIST_CLIENTS_BY_COMPANY,
}

import { gql } from '@apollo/client'

import { userFragments } from './User.gql'
import { errorMessageFragment } from './ErrorMessage.gql'

export const clientReviewFragment = gql`
  fragment clientReviewFragment on ClientReview {
    id
    created
    createdBy {
      ...compactUserFragment
    }
    updated
    updatedBy {
      ...compactUserFragment
    }
    name
    firm
    role
    comment
    rating
  }
  ${userFragments.compactUser}
`

export const clientReviewResultFragment = gql`
  fragment clientReviewResultFragment on ClientReviewResult {
    errors {
      ...errorMessageFragment
    }
    item {
      ...clientReviewFragment
    }
  }
  ${clientReviewFragment}
  ${errorMessageFragment}
`

export const ADD_CLIENT_REVIEW = gql`
  mutation AddClientReview($projectId: ID!, $input: CreateClientReviewInput!) {
    addClientReview(projectId: $projectId, input: $input) {
      ...clientReviewResultFragment
    }
  }
  ${clientReviewResultFragment}
`

export const UPDATE_CLIENT_REVIEW = gql`
  mutation UpdateClientReview($id: ID!, $input: UpdateClientReviewInput!) {
    updateClientReview(id: $id, input: $input) {
      ...clientReviewResultFragment
    }
  }
  ${clientReviewResultFragment}
`

export const DELETE_CLIENT_REVIEW = gql`
  mutation DeleteClientReview($id: ID!) {
    deleteClientReview(id: $id) {
      ...clientReviewResultFragment
    }
  }
  ${clientReviewResultFragment}
`

export const LIST_CLIENT_REVIEWS = gql`
  query ListClientReviews($projectId: ID!) {
    clientReviews(projectId: $projectId) {
      ...clientReviewFragment
    }
  }
  ${clientReviewFragment}
`

export default {
  ADD_CLIENT_REVIEW,
  UPDATE_CLIENT_REVIEW,
  DELETE_CLIENT_REVIEW,
  LIST_CLIENT_REVIEWS,
}

import { gql } from '@apollo/client'

import { commentFragments } from './Comment.gql'
import { userFragments } from './User.gql'
import { errorMessageFragment } from './ErrorMessage.gql'

export const checklistItemFragments = {
  checklistItem: gql`
    fragment checklistItemFragment on ChecklistItem {
      id
      order
      name
      # checklist
      comments {
        ...commentFragment
      }
      complete
      completedBy {
        ...userFragment
      }
      assigned {
        ...userFragment
      }
      due
      created
      updated
      deleted
    }
    ${commentFragments.comment}
    ${userFragments.user}
  `,
}

export const ADD_CHECKLIST_ITEM = gql`
  mutation AddChecklistItem(
    $checklistId: ID!
    $input: CreateChecklistItemInput!
  ) {
    addChecklistItem(checklistId: $checklistId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...checklistItemFragment
      }
    }
  }
  ${errorMessageFragment}
  ${checklistItemFragments.checklistItem}
`
export const UPDATE_CHECKLIST_ITEM = gql`
  mutation UpdateChecklistItem($id: ID!, $input: UpdateChecklistItemInput!) {
    updateChecklistItem(id: $id, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...checklistItemFragment
      }
    }
  }
  ${errorMessageFragment}
  ${checklistItemFragments.checklistItem}
`
export const DELETE_CHECKLIST_ITEM = gql`
  mutation DeleteChecklistItem($id: ID!) {
    deleteChecklistItem(id: $id) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...checklistItemFragment
      }
    }
  }
  ${errorMessageFragment}
  ${checklistItemFragments.checklistItem}
`

import { gql } from '@apollo/client'

import { checklistItemFragments } from './ChecklistItem.gql'

export const checklistFragments = {
  checklist: gql`
    fragment checklistFragment on Checklist {
      id
      order
      name
      items {
        ...checklistItemFragment
      }
      created
      updated
      deleted
    }
    ${checklistItemFragments.checklistItem}
  `,
}

export const ADD_CHECKLIST = gql`
  mutation AddChecklist($projectId: ID!, $input: CreateChecklistInput!) {
    addChecklist(projectId: $projectId, input: $input) {
      ...checklistFragment
    }
  }
  ${checklistFragments.checklist}
`

export const UPDATE_CHECKLIST = gql`
  mutation UpdateChecklist(
    $id: ID!
    $projectId: ID!
    $input: UpdateChecklistInput!
  ) {
    updateChecklist(id: $id, projectId: $projectId, input: $input) {
      ...checklistFragment
    }
  }
  ${checklistFragments.checklist}
`

export const DELETE_CHECKLIST = gql`
  mutation DeleteChecklist($id: ID!) {
    deleteChecklist(id: $id) {
      ...checklistFragment
    }
  }
  ${checklistFragments.checklist}
`

import { gql } from '@apollo/client'

import { userFragments } from './User.gql'
import { errorMessageFragment } from './ErrorMessage.gql'

export const documentFragment = gql`
  fragment documentFragment on Document {
    id
    name
    key
    bucket
    region
    type
    comment
    phase
    docType
    createdBy {
      ...compactUserFragment
    }
    created
    updated
    deleted
  }
  ${userFragments.compactUser}
`

export const ADD_DOCUMENT = gql`
  mutation AddDocument($projectId: ID!, $input: CreateDocumentInput!) {
    addDocument(projectId: $projectId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...documentFragment
      }
    }
  }
  ${documentFragment}
  ${errorMessageFragment}
`

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument(
    $id: ID!
    $projectId: ID!
    $input: UpdateDocumentInput!
  ) {
    updateDocument(id: $id, projectId: $projectId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...documentFragment
      }
    }
  }
  ${documentFragment}
  ${errorMessageFragment}
`

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: ID!, $projectId: ID!) {
    deleteDocument(id: $id, projectId: $projectId) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...documentFragment
      }
    }
  }
  ${documentFragment}
  ${errorMessageFragment}
`

import { gql } from '@apollo/client'

import { userFragments } from './User.gql'
import { errorMessageFragment } from './ErrorMessage.gql'

export const activityFragment = gql`
  fragment activityFragment on Activity {
    description
    actionType
    type
    user {
      ...userFragment
    }
    project {
      id
    }
    createdBy {
      ...userFragment
    }
    created
  }
  ${userFragments.user}
`

export const ADD_ACTIVITY = gql`
  mutation AddActivity($projectId: ID!, $input: CreateActivityInput!) {
    addActivity(projectId: $projectId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...activityFragment
      }
    }
  }
  ${activityFragment}
  ${errorMessageFragment}
`

import { gql } from '@apollo/client'

import { checklistFragments } from './Checklist.gql'
import { commentFragments } from './Comment.gql'
import { costSavingFragment } from './CostSaving.gql'
import { documentFragment } from './Document.gql'
import { estimateFragment } from './Estimate.gql'
import { issueFragments } from './Issue.gql'
import { linkFragments } from './Link.gql'
import { longLeadItemFragment } from './LongLeadItem.gql'
import { milestoneFragments } from './Milestone.gql'
import { partnerFragments } from './Partner.gql'
import { photoFragment } from './Photo.gql'
import { uniqueFeatureFragments } from './UniqueFeature.gql'
import { userFragments } from './User'

export const preconstructionFragment = gql`
  fragment preconstructionFragment on Preconstruction {
    summary
    totalSF
    constructionType
    projectDelivery
    phase
    designStart
    designFinish
    constructionStart
    constructionFinish
    costSavingsTotal
    clientManager {
      ...userFragment
    }
    preconManager {
      ...userFragment
    }
    currentProjectUpdate {
      ...commentFragment
    }
    projectUpdates {
      ...commentFragment
    }
    deliverables {
      ...documentFragment
    }
    milestones {
      ...milestoneFragment
    }
    resources {
      ...linkFragment
    }
    currentEstimate {
      ...estimateFragment
    }
    estimates {
      ...estimateFragment
    }
    costSavings {
      ...costSavingFragment
    }
    regulatoryDocuments {
      ...documentFragment
    }
    checklists {
      ...checklistFragment
    }
    issues {
      ...issueFragment
    }
    longLeadItems {
      ...longLeadItemFragment
    }
    partners {
      ...partnerFragment
    }
    uniqueFeatures {
      ...uniqueFeatureFragment
    }
    photos {
      ...photoFragment
    }
    scheduleRisk
    costRisk
    bidding
    active
    created
    updated
  }
  ${userFragments.user}
  ${commentFragments.comment}
  ${documentFragment}
  ${milestoneFragments.milestone}
  ${linkFragments.link}
  ${estimateFragment}
  ${costSavingFragment}
  ${checklistFragments.checklist}
  ${issueFragments.issue}
  ${longLeadItemFragment}
  ${partnerFragments.partner}
  ${uniqueFeatureFragments.uniqueFeature}
  ${photoFragment}
`

export const updatePreconstruction = gql`
  mutation UpdatePreconstruction(
    $project: ID!
    $input: UpdatePreconstructionInput!
  ) {
    updatePreconstruction(project: $project, input: $input) {
      ...preconFragment
    }
  }
  ${preconstructionFragment}
`

import { gql } from '@apollo/client'

import { userFragments } from './User'
import { ObservationCategoryFragment } from './Observation.gql'

export const InspectionFragment = gql`
  fragment InspectionFragment on Inspection {
    id
    inspector {
      ...userFragment
    }
    inspectionDate
    type
    observations {
      ...ObservationFragment
    }
    categories {
      ...ObservationCategoryFragment
    }
  }
  ${userFragments.user}
  ${ObservationCategoryFragment}
`

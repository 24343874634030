import { gql } from '@apollo/client'

export const vistaCostFragment = gql`
  fragment vistaCostFragment on VistaCost {
    bidDayFee
    bidDayFeePercent
    billed
    billedPercent
    changeToDate
    completionDate
    completionEstimatedPercent
    completionProjectedPercent
    costCompleteProjectedFee
    costEstimatedPercent
    costProjectedPercent
    currentContract
    feeHistory {
      ... on VistaFeeHistory {
        bidDayFee
        projectedFee
        internalContingency
        otherContingency
        month
      }
    }
    feeWithCOs
    feeWithCOsPercent
    feeWithVariance
    feeWithVariancePercent
    gcAverage
    gcBudget
    gcCostToDate
    gcLastMonth
    gcProjected
    gcRemainingMonthly
    gcsProjectThru
    gcVariance
    grAverage
    grBudget
    grCostToDate
    grLastMonth
    grProjected
    grRemainingMonthly
    grVariance
    internalContingency
    laborVariance
    originalContract
    otherContingency
    pendingOCO
    projectedFee
    projectedFeePercent
    startDate
    substantialCompletionDate
    totalAverage
    totalBudget
    totalCashFlow
    totalCostToDate
    totalLastMonth
    totalProjected
    totalRemainingMonthly
    totalVariance
    risks {
      fee
      cashFlow
      gcgr
      billing
    }
  }
`

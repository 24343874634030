import { gql } from '@apollo/client'

import { userFragments } from './User.gql'
import { errorMessageFragment } from './ErrorMessage.gql'

export const milestoneFragments = {
  milestone: gql`
    fragment milestoneFragment on Milestone {
      id
      order
      milestone
      baselineStart
      baselineFinish
      currentStart
      currentFinish
      note
      phase
      complete
      preset
      createdBy {
        ...compactUserFragment
      }
      actual
      variance
      created
      updated
      deleted
    }
    ${userFragments.compactUser}
  `,
}

export const ADD_MILESTONE = gql`
  mutation AddMilestone($projectId: ID!, $input: CreateMilestoneInput!) {
    addMilestone(projectId: $projectId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...milestoneFragment
      }
    }
  }
  ${milestoneFragments.milestone}
  ${errorMessageFragment}
`

export const DELETE_MILESTONE = gql`
  mutation DeleteMilestone($id: ID!, $projectId: ID!) {
    deleteMilestone(id: $id, projectId: $projectId) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...milestoneFragment
      }
    }
  }
  ${milestoneFragments.milestone}
  ${errorMessageFragment}
`

export const UPDATE_MILESTONE = gql`
  mutation UpdateMilestone(
    $id: ID!
    $projectId: ID!
    $input: UpdateMilestoneInput!
  ) {
    updateMilestone(id: $id, projectId: $projectId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...milestoneFragment
      }
    }
  }
  ${milestoneFragments.milestone}
  ${errorMessageFragment}
`

import { gql } from '@apollo/client'

export const userFragments = {
  login: gql`
    fragment userLoginFragment on User {
      id
      email
      name
      superAdmin
      internal
      password
      permissionTemplates {
        id
        name
        internal
        role
        company {
          id
        }
        permissions {
          modules {
            name
            permission
            isDefault
            features {
              name
              permission
            }
          }
          regions {
            name
            permission
            programs {
              name
              permission
            }
          }
        }
      }
      projects {
        project {
          id
          contract
          name
          phase
        }
        assignedByVista
      }
      photo
    }
  `,
  authUser: gql`
    fragment userAuthUserFragment on User {
      id
      email
      name
      superAdmin
      internal
      password
      permissionTemplates {
        id
        name
        internal
        role
        company {
          id
        }
        permissions {
          modules {
            name
            permission
            isDefault
            features {
              name
              permission
            }
          }
          regions {
            name
            permission
            programs {
              name
              permission
            }
          }
        }
      }
      projects {
        project {
          id
          contract
          name
          phase
        }
        assignedByVista
      }
    }
  `,
  user: gql`
    fragment userFragment on User {
      id
      name
      photo
      email
    }
  `,
  settings: gql`
    fragment settingsUserFragment on User {
      id
      name
      title
      email
      internal
      superAdmin
      # photo
      # projectTeams {
      #   id
      #   contract
      #   name
      # }
      permissionTemplates {
        id
        name
        internal
        role
        active
        deleted
        company {
          id
        }
        permissions {
          modules {
            name
            permission
            isDefault
            features {
              name
              permission
            }
          }
          regions {
            name
            permission
            programs {
              name
              permission
            }
          }
        }
      }
    }
  `,
  assignedProjects: gql`
    fragment assignedUserProjectsFragment on UserProject {
      project {
        id
        name
        contract
        company {
          id
        }
      }
      assignedByVista
    }
  `,
  userPermissionsGrid: gql`
    fragment userPermissionsGrid on User {
      id
      name
      title
      email
    }
  `,
}

export const getUserByEmail = gql`
  query getUserByEmail($email: String) {
    getUserByEmail(email: $email) {
      ...userLoginFragment
    }
  }
  ${userFragments.login}
`

export const GET_AUTH_USER = gql`
  query getUserByEmail($email: String) {
    getUserByEmail(email: $email) {
      ...userAuthUserFragment
    }
  }
  ${userFragments.authUser}
`

// not consumed by Apollo Client, but an HTTP call
export const checkUserEmail = `
  query getUserByEmail($email: String) {
    getUserByEmail(email: $email) {
      id
      name
      email
      internal
    }
  }
`

export const listUsersByRole = gql`
  query usersByRole($company: ID!, $role: String!) {
    listUsersByRole(company: $company, role: $role) {
      ...userLoginFragment
    }
  }
  ${userFragments.login}
`

export const GET_SETTINGS_USER = gql`
  query GetUserById($id: ID!) {
    getUserById(id: $id) {
      ...settingsUserFragment
    }
  }
  ${userFragments.settings}
`

export const LIST_SETTINGS_USERS = gql`
  query ListUsers($filter: UserFilter, $limit: Int, $nextToken: String) {
    listUsersConnection(filter: $filter, limit: $limit, nextToken: $nextToken) {
      users {
        ...userPermissionsGrid
      }
      nextToken
    }
  }
  ${userFragments.userPermissionsGrid}
`

export const LIST_USER_ASSIGNED_PROJECTS = gql`
  query ListUserAssignedProjects($id: ID!) {
    listUserAssignedProjects(id: $id) {
      ...assignedUserProjectsFragment
    }
  }
  ${userFragments.assignedProjects}
`

export const GET_LOGGED_IN_USER = gql`
  query GetLoggedInUser {
    loggedInUser @client {
      ...userFragment
    }
  }
  ${userFragments.user}
`

export const GET_USER_BY_ID = gql`
  query GetUserById($id: ID!) {
    getUserById(id: $id) {
      id
      email
      name
      superAdmin
      internal
      photo
      permissionTemplates {
        id
        name
        internal
        role
        company {
          id
        }
        permissions {
          modules {
            name
            permission
            isDefault
            features {
              name
              permission
            }
          }
          regions {
            name
            permission
            programs {
              name
              permission
            }
          }
        }
      }
      projects {
        project {
          id
        }
      }
    }
  }
`

import { gql } from '@apollo/client'

import { errorMessageFragment } from './ErrorMessage.gql'

export const subcontractorScope = gql`
  fragment subcontractorScopeFragment on Scope {
    scope
    originalEstimate
    originalContract
    changeOrders
    currentContract
    actualCostToDate
  }
`

export const subcontractorFragment = gql`
  fragment subcontractorFragment on Subcontractor {
    id
    name
    currentContract
    actualCostToDate
    scopes {
      ...subcontractorScopeFragment
    }
  }
  ${subcontractorScope}
`

export const unassignedScopeFragment = gql`
  fragment unassignedScopeFragment on Scope {
    scope
    currentContract
    actualCostToDate
  }
`

const scopeCommentResultFragment = gql`
  fragment scopeCommentResultFragment on ScopeCommentResult {
    item {
      id
      comment
    }
    errors {
      ...errorMessageFragment
    }
  }
  ${errorMessageFragment}
`

const subCommentResultFragment = gql`
  fragment subcontractorCommentResultFragment on SubcontractorCommentResult {
    item {
      id
      comment
      rating
    }
    errors {
      ...errorMessageFragment
    }
  }
  ${errorMessageFragment}
`

export const UPSERT_SCOPE_COMMENT = gql`
  mutation UpsertScopeComment($input: UpsertScopeCommentInput!) {
    upsertScopeComment(input: $input) {
      ...scopeCommentResultFragment
    }
  }
  ${scopeCommentResultFragment}
`

export const UPSERT_SUBCONTRACTOR_COMMENT = gql`
  mutation UpsertSubcontractorComment(
    $input: UpsertSubcontractorCommentInput!
  ) {
    upsertSubcontractorComment(input: $input) {
      ...subcontractorCommentResultFragment
    }
  }
  ${subCommentResultFragment}
`

export const LIST_SUB_COMMENTS = gql`
  query SubcontractorComments($contract: String!) {
    subcontractorComments(contract: $contract) {
      id
      comment
      rating
    }
  }
`

export const LIST_SCOPE_COMMENTS = gql`
  query ScopeComments($contract: String!) {
    unassignedScopesComments(contract: $contract) {
      id
      comment
    }
  }
`

import { gql } from '@apollo/client'

import { errorMessageFragment } from './ErrorMessage.gql'

export const procurementFragment = gql`
  fragment procurementResult on Procurement {
    id
    type
    proposalReceived
    serviceName
    vendor
    comment
    poNumber
    poValue
    status
    dateNeeded
  }
`

export const ADD_PROCUREMENT = gql`
  mutation AddProcurement($projectId: ID!, $input: AddProcurementInput!) {
    addProcurement(projectId: $projectId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        id
        type
        proposalReceived
        serviceName
        vendor
        comment
        poNumber
        poValue
        status
        dateNeeded
      }
    }
  }
  ${errorMessageFragment}
`

export const UPDATE_PROCUREMENT = gql`
  mutation UpdateProcurement(
    $id: ID!
    $projectId: ID!
    $input: UpdateProcurementInput!
  ) {
    updateProcurement(id: $id, projectId: $projectId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        id
        type
        proposalReceived
        serviceName
        vendor
        comment
        poNumber
        poValue
        status
        dateNeeded
      }
    }
  }
  ${errorMessageFragment}
`

export const DELETE_PROCUREMENT = gql`
  mutation DeleteProcurement($id: ID!, $projectId: ID!) {
    deleteProcurement(id: $id, projectId: $projectId) {
      errors {
        ...errorMessageFragment
      }
      item {
        id
      }
    }
  }
  ${errorMessageFragment}
`

import { gql } from '@apollo/client'

export const AttachmentFragment = gql`
  fragment AttachmentFragment on Attachment {
    id
    originalUrl
    reducedUrl
    thumbnailUrl
    type
  }
`

import { gql } from '@apollo/client'

export const userFragments = {
  compactUser: gql`
    fragment compactUserFragment on User {
      id
      name
      email
      internal
    }
  `,
  user: gql`
    fragment userFragment on User {
      id
      name
      photo
      email
    }
  `,
  inspectorFragment: gql`
    fragment InspectorFragment on User {
      id
      name
      title
    }
  `,
}

export const LIST_USERS = gql`
  query ListUsers($filter: UserFilter) {
    listUsers(filter: $filter) {
      ...userFragment
    }
  }
  ${userFragments.user}
`

// TODO: rename `getUserByEmail` to `GetUserByEmail`
export const GET_USER_PHOTO = gql`
  query getUserByEmail($email: String) {
    getUserByEmail(email: $email) {
      id
      email
      name
      photo
    }
  }
`

import { gql } from '@apollo/client'

export const regionFragment = gql`
  fragment regionFragment on Region {
    id
    name
    vistaCompanies
  }
`

export const listRegionsByCompany = gql`
  query ListRegionsByCompany($company: String!) {
    listRegionsByCompany(company: $company) {
      ...regionFragment
    }
  }
  ${regionFragment}
`

export const CREATE_REGION_INPUT = gql`
  mutation CreateRegionInput($input: CreateRegionInput!) {
    addRegion(input: $input) {
      ...regionFragment
    }
  }
  ${regionFragment}
`

export const UPDATE_REGION_INPUT = gql`
  mutation UpdateRegionInput(
    $id: ID!
    $company: ID!
    $input: UpdateRegionInput!
  ) {
    updateRegion(id: $id, company: $company, input: $input) {
      ...regionFragment
    }
  }
  ${regionFragment}
`

export const DELETE_REGION = gql`
  mutation DeleteRegion($id: ID!, $company: ID!) {
    deleteRegion(id: $id, company: $company) {
      ...regionFragment
    }
  }
  ${regionFragment}
`

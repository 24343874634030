import { gql } from '@apollo/client'

export const teamMemberFragments = {
  teamMember: gql`
    fragment teamMemberFragment on TeamMemberInterface {
      id
      name
      email
      title
      phone
      photo
    }
  `,
}

export const ADD_TEAM_MEMBER = gql`
  mutation AddTeamMember(
    $project: ID!
    $teamMember: ID!
    $assignedByVista: Boolean
  ) {
    addTeamMember(
      project: $project
      teamMember: $teamMember
      assignedByVista: $assignedByVista
    ) {
      ...teamMemberFragment
    }
  }
  ${teamMemberFragments.teamMember}
`

export const REMOVE_TEAM_MEMBER = gql`
  mutation RemoveTeamMember($project: ID!, $teamMember: ID!) {
    removeTeamMember(project: $project, teamMember: $teamMember) {
      ...teamMemberFragment
    }
  }
  ${teamMemberFragments.teamMember}
`

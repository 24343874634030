import { gql } from '@apollo/client'

import { userFragments } from './User.gql'
import { errorMessageFragment } from './ErrorMessage.gql'

export const estimateFragment = gql`
  fragment estimateFragment on Estimate {
    id
    name
    approvedProjectBudget
    currentProjectEstimate
    currentConstructionEstimate
    projectVariance
    internalContingency
    externalContingency
    gcs
    duration
    note
    createdBy {
      ...compactUserFragment
    }
    created
    updated
    deleted
    active
  }
  ${userFragments.compactUser}
`

export const ADD_ESTIMATE = gql`
  mutation AddEstimate($projectId: ID!, $input: CreateEstimateInput!) {
    addEstimate(projectId: $projectId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...estimateFragment
      }
    }
  }
  ${estimateFragment}
  ${errorMessageFragment}
`

export const UPDATE_ESTIMATE = gql`
  mutation UpdateEstimate(
    $id: ID!
    $projectId: ID!
    $input: UpdateEstimateInput!
  ) {
    updateEstimate(id: $id, projectId: $projectId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...estimateFragment
      }
    }
  }
  ${estimateFragment}
  ${errorMessageFragment}
`

export const DELETE_ESTIMATE = gql`
  mutation DeleteEstimate($id: ID!, $projectId: ID!) {
    deleteEstimate(id: $id, projectId: $projectId) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...estimateFragment
      }
    }
  }
  ${estimateFragment}
  ${errorMessageFragment}
`

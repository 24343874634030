import { gql } from '@apollo/client'

import { clientFragment } from './Client.gql'

export const vistaProjectInfoFragment = gql`
  fragment vistaProjectInfoFragment on VistaProjectInfo {
    client {
      ...clientFragment
    }
    architect
    deliveryMethod
    industryType
    constructionType
    projectSize
    foundationType
    structuralFrame
    exteriorSkin
    roofSystem
  }
  ${clientFragment}
`

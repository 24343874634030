import { gql } from '@apollo/client'

import { userFragments } from './User.gql'
import { errorMessageFragment } from './ErrorMessage.gql'

export const longLeadItemFragment = gql`
  fragment longLeadItemFragment on LongLeadItem {
    id
    description
    leadTime
    orderDate
    createdBy {
      ...userFragment
    }
    created
    updated
    deleted
  }
  ${userFragments.user}
`

export const longLeadItemResultFragment = gql`
  fragment longLeadItemResultFragment on LongLeadItemResult {
    item {
      ...longLeadItemFragment
    }
    errors {
      ...errorMessageFragment
    }
  }
  ${longLeadItemFragment}
  ${errorMessageFragment}
`

export const ADD_LONG_LEAD_ITEM = gql`
  mutation AddLongLeadItem($projectId: ID!, $input: CreateLongLeadItemInput!) {
    addLongLeadItem(projectId: $projectId, input: $input) {
      ...longLeadItemResultFragment
    }
  }
  ${longLeadItemResultFragment}
`

export const UPDATE_LONG_LEAD_ITEM = gql`
  mutation UpdateLongLeadItem(
    $id: ID!
    $projectId: ID!
    $input: UpdateLongLeadItemInput!
  ) {
    updateLongLeadItem(id: $id, projectId: $projectId, input: $input) {
      ...longLeadItemResultFragment
    }
  }
  ${longLeadItemResultFragment}
`

export const DELETE_LONG_LEAD_ITEM = gql`
  mutation DeleteLongLeadItem($id: ID!, $projectId: ID!) {
    deleteLongLeadItem(id: $id, projectId: $projectId) {
      ...longLeadItemResultFragment
    }
  }
  ${longLeadItemResultFragment}
`

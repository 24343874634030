import { gql } from '@apollo/client'

import { userFragments } from './User'
import { errorMessageFragment } from './ErrorMessage.gql'

export const issueFragments = {
  issue: gql`
    fragment issueFragment on Issue {
      id
      created
      priority
      issue
      resolution
      ballInCourt
      due
      scheduleImpact
      status # open = true, closed = false
      updated
      deleted
      createdBy {
        ...userFragment
      }
    }
    ${userFragments.user}
  `,
}

export const ADD_ISSUE = gql`
  mutation AddIssue($projectId: ID!, $input: CreateIssueInput!) {
    addIssue(projectId: $projectId, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...issueFragment
      }
    }
  }
  ${issueFragments.issue}
  ${errorMessageFragment}
`

export const DELETE_ISSUE = gql`
  mutation DeleteIssue($id: ID!) {
    deleteIssue(id: $id) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...issueFragment
      }
    }
  }
  ${issueFragments.issue}
  ${errorMessageFragment}
`

export const UPDATE_ISSUE = gql`
  mutation UpdateIssue($id: ID!, $input: UpdateIssueInput!) {
    updateIssue(id: $id, input: $input) {
      errors {
        ...errorMessageFragment
      }
      item {
        ...issueFragment
      }
    }
  }
  ${issueFragments.issue}
  ${errorMessageFragment}
`
